import Atelier from '@/models/Atelier'
import Program from '@/models/Program'
import {computed} from "vue";
import {useStore} from "@/store";
import Page from "@/models/Page";
import Artist from "@/models/Artist";
import News from "@/models/News";

export const useContent = () => {
    const store = useStore()

    const pages = computed<Page[]>(() => {
        return store.stories.filter((story: any) => story.type === "page").filter(s => s.title) || []
    })

    const artists = computed<Artist[]>(() => {
        return store.stories.filter((story: any) => story.type === "artist").filter(s => s.title) || []
    })

    const ateliers = computed<Atelier[]>(() => {
        return store.stories.filter((story: any) => story.type === "hk_atelier").filter(s => s.title) || []
    })

    const news = computed<News[]>(() => {
        return store.stories.filter((story: any) => story.type === "news").filter(s => s.title) || []
    })

    const programs = computed<Program[]>(() => {
        return store.stories.filter((story: any) => story.type === "program").filter(s => s.title) || []
    })

    const getStoryByUuid = (uuid: string) => {
        return store.stories.find((story: any) => story.uuid === uuid) || null
    }

    return {
        news,
        pages,
        artists,
        ateliers,
        programs,
        getStoryByUuid
    }
}

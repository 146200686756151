import {useStore} from "@/store";
import {InjectionKey, Ref, toRaw} from "vue";
import {
    modalController,
    onIonViewDidEnter,
    onIonViewDidLeave
} from "@ionic/vue";



export const ModalWrapper: InjectionKey<Ref<HTMLElement>> = Symbol('ModalWrapper')
export const useModal = async (story: any, wrapper: any) => {

    const store = useStore()
    const modal = await modalController.create({
        component: toRaw(story.view),
        componentProps: { data: story },
        showBackdrop: false,
        canDismiss: true,
        mode: 'ios',
        presentingElement: wrapper.value.$el
    })

    store.modalOpened++
    await modal.present()
    await modal.onWillDismiss()
    store.modalOpened--
}

export const disableSideMenuSwipe = () => {
    const store = useStore()
    onIonViewDidEnter(() => {
        store.disableMenu = true
    })

    onIonViewDidLeave(() => {
        store.disableMenu = false
    })
}

import BaseModel from '@/models/BaseModel'
import AtelierDetail from "@/modals/AtelierDetail.vue";
import Program from '@/models/Program'
import { useStore } from '@/store'
import { toRefs } from 'vue'

export default class Atelier extends BaseModel {
  public declare excerpt: { rendered: string }
  public declare id?: string
  public declare meta: Record<string, string[]>
  public declare featured_image?: string
  public declare image300?: string
  public declare image1200?: string
  public declare content: Record<string, any>
  public declare title: { rendered: string }
  public declare tags: string[]

  // public get name() : string {
  //   return this.title.rendered
  // }

  // public get type() : string {
  //   return 'atelier'
  // }

  public get stage() : string {
    // TODO: implement
    return `this.content.stage`
  }

  public get program(): Program[] {
    const store = useStore()
    const items = store.stories.filter((story: any) => story.content.stage === this.id) as Program[]
    return items.sort((a, b) => {
      return a.timeStart < b.timeStart ? -1 : 1
    })
  }

  public get image() {
    return this.featured_image ? this.featured_image : null
  }
  public get thumb() {
    // return this.image ? `${this.image}/m/300x300/smart` : ''
    return this.image300 ? this.image300 : null
  }
  public get picture() {
    return this.image1200 ? this.image1200 : null
  }
  public get perex() {
    return this.excerpt.rendered
  }
  public get body() {
    return this.content.rendered
  }

  public get tagList() : string[] {
    const {tags} = toRefs(useStore())
    return this.tags.map(tag => tags.value.find(t => t.id === Number(tag))?.name || tag)
  }

  public get floor() {
    return Number(this.meta.floor?.[0] || 0)
  }

  public get location() : 'campus' | 'dorms' | 'school' | undefined {
    return this.meta.loc?.[0] || 'school' as any
  }

  public get locationTitle() {
    const titles = {
      'campus': 'Areál',
      'school': 'Škola',
      'dorms': 'Internát'
    } as Record<string, string>

    return titles[this.location!]
  }

  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram'].map(key => ({
      icon: key,
      link: this.content[key] || null
    })).filter(i => i.link)
  }

  public get view() {
    return AtelierDetail
  }
}

import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import {useStore} from "@/store";
import {SplashScreen} from "@capacitor/splash-screen";
import {Device} from "@capacitor/device";

import TabsPage from '../views/TabsPage.vue'
import Homepage from "@/views/Homepage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/homepage'
  },
  {
    path: '/ateliers/:id',
    component: () => import('@/modals/AtelierDetail.vue')
  },
  {
    path: '/program/:id',
    component: () => import('@/modals/ProgramDetail.vue')
  },
  {
    path: '/news/:id',
    component: () => import('@/modals/NewsDetail.vue')
  },
  {
    path: '/pages/:id',
    component: () => import('@/modals/PageDetail.vue')
  },
  {
    path: '/pages',
    component: () => import('@/modals/PageDetail.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/homepage'
      },
      {
        path: 'homepage',
        component: Homepage
      },
      {
        path: 'ateliers',
        component: () => import('@/views/Ateliers.vue')
      },
      {
        path: 'schedule',
        component: () => import('@/views/Schedule.vue')
      },
      {
        path: 'map',
        component: () => import('@/views/Map.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach(async () => {
  const store = useStore()
  await store.loadStories()

  if(!store.platform) {
    const info = await Device.getInfo();
    store.platform = info.platform
  }

  if(store.splashHidden || store.platform === 'web') return

  await new Promise(resolve => setTimeout(resolve, 2000))
  await SplashScreen.hide({
    fadeOutDuration: 2000
  })
  store.splashHidden = true
})

export default router

import ProgramDetail from '@/modals/ProgramDetail.vue'
import Atelier from '@/models/Atelier'
import Story from "@/models/Story";
import {useStore} from "@/store";
import {Image} from "@/models/Image";
import {formatTime} from "@/utils";
import {computed} from "vue";


export default class Program extends Story {

  public get stage() {
    // TODO: implement
    const store = useStore()
    return store.getStoryById(this.content.stage) as Atelier
  }

  public get image(): Image {
    return this.content?.image?.filename ? this.content.image : null
  }
  public get thumb(): string {
    return this.image ? `${this.image.filename}/m/300x300/smart` : ''
  }
  public get picture(): string {
    return this.image ? `${this.image.filename}/m/1200x1200/smart` : ''
  }
  public get perex() {
    return this.content.perex
  }
  public get blocks() {
    return this.content.content
  }
  public get duration() {
    return this.content.duration
  }
  public get tags() {
    return this.content.tags
  }
  public get location() {
    return this.content.location
  }

  public get locationTitle() {
    const titles = {
      'campus': 'Areál',
      'school': 'Škola',
      'dorms': 'Internát'
    } as Record<string, string>

    return titles[this.location]
  }

  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram'].map(key => ({
      icon: key,
      link: this.content[key] || null
    })).filter(i => i.link)
  }
  public get timeStart() {
    return formatTime(this.content.time_start)
  }
  public get timeEnd() {
    return formatTime(this.content.time_start).plus({minutes: this.duration})
  }

  public get timeFormatted() {
    return `${this.timeStart.toFormat('HH:mm')}-${this.timeEnd.toFormat('HH:mm')}`
  }

  public get timeFormattedShort() {
    return `${this.timeStart.toFormat('HH:mm')} - ${this.timeEnd.toFormat('HH:mm')}`
  }

  public get isLiked() {
    const store = useStore()
    return computed(() => store.likes.find(l => l.uuid === this.uuid))
  }
  public get view() {
    return ProgramDetail
  }
}

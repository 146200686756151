<template>
  <ion-content class="more">
    <ion-header >
      <ion-toolbar >

      </ion-toolbar>
    </ion-header>

    <Pages />
  </ion-content>
</template>
<script>


</script>
<style lang="scss" scoped>
  .more {
    display: flex;
    border-left: 3px solid black;
  }
</style>

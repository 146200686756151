import Atelier from '@/models/Atelier'
import Program from '@/models/Program'
import {defineStore} from "pinia";
import {useStoryblokApi} from "@storyblok/vue";
import Page from "@/models/Page";
import Artist from "@/models/Artist";
import News from "@/models/News";
import Settings from "@/models/Settings";
import {DateTime} from "ts-luxon";
import {allDoc} from "@/plugins/firestore";
import {onSnapshot} from "firebase/firestore";
import { CapacitorHttp, HttpResponse } from '@capacitor/core'


const doFetch = async (url: string) => {
    const options = {
        url,
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    return response.data;
};

export type Section = 'campus' | 'school' | 'dorms'
export const useStore = defineStore('store', {
    state: () => ({
        section: 'campus' as 'campus' | 'school' | 'dorms',
        favSorting: 'time' as 'title' | 'time',
        platform: null as string | null,
        splashHidden: false,
        disableMenu: false,
        leftOpened: false,
        rightOpened: false,
        modalOpened: 0,

        stories: [] as any[],
        storiesUpdated: null as null | number,

        fcmToken: null as null | string,
        likes: [] as {
            uuid: string,
            type?: 'artist' | 'speaker' | 'timeslot'
        }[],
        now: DateTime.now(),
        config: {} as Settings,
        blurhash: [] as {
            uuid: string,
            hash: string
            url: string
        }[],
        tags: [] as {
            id: number,
            name: string
        }[]
    }),
    getters: {
        getStoryByUuid: (state) => (uuid: string) => {
            return state.stories.find((story: any) => story.uuid === uuid) || null
        },
        getStoryById: (state) => (id: string) => {
            return state.stories.find((story: any) => story.id === id) || null
        },
        getStoryBySlug: (state) => (slug: string) => {
            return state.stories.find((story: any) => story.slug === slug) || null
        },
        getLikeByUuid: (state) => (uuid: string) => {
            return state.likes.find((story: any) => story.uuid === uuid) || null
        },
        getHashByUrl: (state) => (url: string) => {
            return state.blurhash.find((h) => h.url === url) || null
        },
        getHashByUuid: (state) => (uuid: string) => {
            return state.blurhash.find((h) => h.uuid === uuid) || null
        }
    },
    actions: {
        async loadStories() {
            // Poor mans cron
            const now = DateTime.now()

            if(this.storiesUpdated) {
                const cacheTime = DateTime.fromSeconds(this.storiesUpdated)
                    .plus({minutes: 5})

                if(now < cacheTime)
                    return
            }


            const perPage = 100;
            const storyblokApi = useStoryblokApi();
            const fetchStories = async (page: number) => {
                const { data, total } = await storyblokApi.get("cdn/stories", {
                    version: "draft",
                    per_page: perPage,
                    page: page,
                });
                return {
                    stories: hydrateStories(data.stories),
                    total,
                };
            };

            const getAllStories = async () => {
                let page = 1;
                let total = 1;
                const stories = [];

                while (stories.length < total) {
                    const response = await fetchStories(page);

                    stories.push(...response.stories);
                    total = response.total;
                    page++;

                    // console.log(`Loaded ${stories.length} of ${total} stories...`)
                }

                return stories;
            };

            const getAllTags = async () => {
                // https://novacvernovka.eu/wp-json/wp/v2/tags?per_page=100&page=8
                const tags =  await Promise.all([...Array(9).keys()].map(async (page) => {
                    const response = await doFetch(`https://novacvernovka.eu/wp-json/wp/v2/tags?per_page=100&page=${page + 1}`)
                    return response
                })).then((tags) => tags.flat().map((tag: any) => ({
                    id: tag.id,
                    name: tag.name
                }))
                )

                // console.log("All tags loaded...", tags)
                return tags
            }

            this.stories = await getAllStories();
            getAllTags().then(tags => this.tags = tags)
            // console.log("All stories loaded...", this.stories)

            this.config = this.stories
                .filter((story: any) => story.type === "settings")[0] || {}
            this.storiesUpdated = now.toUnixInteger()

            const response = await doFetch('https://novacvernovka.eu/wp-json/wp/v2/hk_atelier?per_page=100')
            const ateliers = response

            this.stories = this.stories.concat(ateliers.map((a: any) => new Atelier({
                ...a,
                title: a.title.rendered
            })))

            blurHash(this.stories, this)
        }
    },
    persist: {
        key: 'store2024',
        afterRestore: (ctx) => {
            ctx.store.now = DateTime.now()
            ctx.store.splashHidden = false
            ctx.store.stories = hydrateStories(ctx.store.stories)
            ctx.store.config = ctx.store.stories
                .filter((story: any) => story.type === "settings")[0] || {}
        }
    }
})

const blurHash = async (stories: any[], store: any) => {

    return new Promise(function(res) {

        // const thumbs = stories.filter(s => s.thumb).map(s => ({
        //     uuid: s.thumb.split("/")[6],
        //     url: s.thumb,
        //     hash: null
        // }))

        // console.log(thumbs)

        onSnapshot(allDoc, (doc) => {
            const data = doc.data()
            const content = JSON.parse(data?.content)
            store.blurhash = content
            // console.log("Hashes loaded...")
            res(content)
        })
    })
}

const hydrateStories = (data: any) => {
    return data.map((story: any) => {
        switch (story.content.component) {
            case "news":
                return new News(story)
            case "artist":
                return new Artist(story)
            case "page":
                return new Page(story)
            case "settings":
                return new Settings(story)
            case "program":
                return new Program(story)
            default:
                return new Atelier(story)
        }
    })
}
